import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import {
  appConfig,
  keycloakProviders,
  translateProviders,
} from '@app/app.config';
import * as Sentry from '@sentry/angular-ivy';
import { environement } from '@app/environments/environment';
import { IS_PRODUCTION } from '@utils/constants';

if (IS_PRODUCTION) {
  Sentry.init({
    dsn: environement.FRONT_SENTRY_DSN,
    environment: environement.ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'https://dtt-etg-ihm-main.qual.tahitinumerique.com/',
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

bootstrapApplication(AppComponent, {
  ...appConfig,
  providers: [
    ...appConfig.providers,
    ...translateProviders,
    ...keycloakProviders,
  ],
});
